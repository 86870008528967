<template>
  <div>
    <v-row class="heading">
      <div class="text-h6">Procedures</div>
      <v-spacer></v-spacer>
      <v-btn
        v-policy:module-update="module"
        @click="addProcedure"
        color="primary"
      >
        Add
      </v-btn>
    </v-row>
    <v-spacer></v-spacer>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="innerProcedures"
        item-key="id"
        @click:row="viewProcedure"
        :disable-pagination="true"
        :hide-default-footer="true"
        disable-sort
      >
        <template v-slot:item.name="{item}">
          {{ item.name | truncate(250) }}
        </template>

        <template v-slot:item.actions="{item, index}">
          <v-btn
            v-if="index > 0"
            icon
            small
            title="Move Up"
            @click.stop="moveItem(item, index -1 )"
            v-policy:module-update="module"
          >
            <v-icon>mdi-chevron-up</v-icon>
          </v-btn>
          <v-btn
            v-if="index < innerProcedures.length - 1"
            icon
            small
            title="Move Down"
            @click.stop="moveItem(item, index + 1)"
            v-policy:module-update="module"
          >
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import _ from 'lodash'
import { components as componentsApiBuilder } from '@/services/api'

export default {
  props: {
    module: { type: Object, required: true },
    component: { type: Object, required: true },
    procedures: { type: Array, required: true }
  },
  data() {
    return {
      componentsApi: null,
      innerProcedures: [],
      headers: [
        { text: 'Name', value: 'name', width: '75%' },
        { text: 'Status', value: 'status', width: '10%' },
        { text: 'Version', value: 'version', width: '10%', align: 'right' },
        { text: '', value: 'actions', sortable: false, width: '10%' }
      ],
      footerProps: {
        showItemsPerPage: false
      }
    }
  },
  watch: {
    procedures(val){
      this.sort(val)
    }
  },
  mounted() {
    this.componentsApi = componentsApiBuilder.build(this.module.id)
    if (this.procedures){
      this.sort(this.procedures)
    }
  },
  methods: {
    sort(val){
      if(!val) return

      const orderSorter = 'order'
      const nameSorter = item => item.name.toLowerCase()

      const arr  = _.sortBy(val, [orderSorter, nameSorter])
      arr.forEach((item, idx) => {
        item.order = idx
      })  

      this.innerProcedures = [...arr]
    },
    addProcedure() {
      this.$emit('addProcedure')
    },
    viewProcedure(item) {
      this.$emit('viewProcedure', item)
    },
    async moveItem(item, idx){
      this.innerProcedures.splice(idx, 0, this.innerProcedures.splice(item.order, 1)[0])
      this.innerProcedures.forEach((item, idx) => {
        item.order = idx
      })
      
      await this.componentsApi.updateProcedureOrder(this.component.id, Object.fromEntries(this.innerProcedures.map(item => [item.id, item.order])))

      this.$emit('proceduresUpdated', this.innerProcedures)
    }
  }
}
</script>

<style scoped>
.heading {
  padding: 25px 25px 25px 25px;
}
</style>
